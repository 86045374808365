import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import ClientsList from '../components/ClientsList';
import MoreArticles from '../components/articles/MoreArticles';
import { Article } from '../styles/article';
import ArticleHeader from '../components/articles/ArticleHeader';
import Form from '../components/form/Form';
import { themeColor } from '../styles/theme';
import { Container } from '../styles/contact';
import * as FormHeader from '../components/form/FormHeader';

const WrapperArticle = styled.div`
  padding-top: 6.7rem;
  
  blockquote {
    line-height: 1.3;
    position: relative;
    margin-left: 3rem;
    margin-bottom: 1rem;
    
    &:before {
      position: absolute;
      content: '“';
      font-size: 90px;
      top: -15px;
      left: -50px;
      right: 14px;
    }
  }
  .author {
    text-align: right;
    font-weight: 600;
  }
`;

const Wrapper = styled.section`
  @media (min-width: 768px) {
    display: flex;
  }
  
  @media (min-width: 1024px) {
    min-height: 820px;
  }
`;

const SoftwarePartner = () => {
  const data = {
    title: 'The Software Partner You’re Looking For',
    label: 'No babysitter required',
    excerpt: 'At New Normal, we don’t offer a one-size-fits-all answer. It comes down to technology, economics, and supportability. We’ll guide you through the decision process and help you select the right framework.',
    html: '<p><b>“What we need is an app.”</b></p><p>Yeah, you probably do. But how to proceed? Do you need iOS and Android developers? Or maybe it should work cross-platform? Maybe it’s web-only. How do you decide?</p><p>At New Normal, we don’t offer a one-size-fits-all answer. It comes down to technology, economics, and supportability. We’ll guide you through the decision process and help you select the right framework.</p><p>Then we’ll build it together with as much future-proofing as possible.</p><blockquote><i>Over the last five years, New Normal has become an indispensable partner in thinking through the complexities of music catalog valuations. They’ve been key in turning our many business considerations into a robust algorithm we’re confident is superior to our competitors.</i></blockquote><p class="author">— Greg Spils, Open On Sunday COO</p>',
  };
  const {
    title,
    excerpt,
    html,
    label,
  } = data;

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        keywords={['web', 'apps', 'development']}
      />
      <ArticleHeader {...data} showLabel={label} />
      <Wrapper>
        <Container>
          <WrapperArticle>
            <Article dangerouslySetInnerHTML={{ __html: html }} />
          </WrapperArticle>
        </Container>
        <Container css={{
          display: 'flex',
          justifyContent: 'center',
          padding: '40px 15px',
          backgroundColor: themeColor('primary'),
        }}
        >
          <div css={{ maxWidth: '550px', paddingTop: '5rem' }}>
            <FormHeader.Title>Let us make you look smart</FormHeader.Title>
            <FormHeader.Text>
              We love building secure code that lasts. What can we build for you?
            </FormHeader.Text>
            <Form />
          </div>
        </Container>
      </Wrapper>
      <main>
        <MediaQuery
          minDeviceWidth={768}
        >
          <ClientsList
            title="Trusted by Pacific Northwest companies large and small"
          />
        </MediaQuery>
      </main>
      <MoreArticles text="Check our latest case studies" />
    </Layout>
  );
};

export default SoftwarePartner;
